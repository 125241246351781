

import { firestore, tableNames } from '../constants/Config';
import { collection, getDocs, query, orderBy, where, limit, setDoc, doc, addDoc, or, and } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Units } from '../constants/Unit';

// ====================  Start getting level data  ======================
export function getLevel() {
    // firestore.setLogLevel('debug');
    return new Promise((resolve, reject) => {
        try {
            const q = query(collection(firestore, `${tableNames.level}`), orderBy("LevelId", "asc"))
            // const querySnapshot = await getDocs(q)
            getDocs(q).then(querySnapshot => {
                let level = [];
                let count = 0;
                if (querySnapshot !== null) {
                    querySnapshot.forEach(async documentSnapshot => {
                        count++;
                        level.push({
                            id: documentSnapshot.id,
                            ...documentSnapshot.data(),
                        });
                        if (count === querySnapshot.size) {
                            resolve(level);
                        }
                    });
                }
            }).catch((error) => {
                console.log("error ::", error)
                reject(error.code);
            });
        } catch (err) {
            console.log("getLevel error: ", err)
            reject(err);
        }
    });
}

export function trackPracticeForUser(data) {

    const sessionId = data.SessionId
    //Checks if the user already has a session so that we dont record the user practice data twice
    const q = query(collection(firestore, `${tableNames.practiceReport}`), where("SessionId", "==", sessionId))

    getDocs(q).then((snapshot) => {
        if (snapshot.size === 0) {
            // collection(firestore, `${tableNames.practiceReport}`).add(data)
            addDoc(collection(firestore, `${tableNames.practiceReport}`), data);
        }
    }).catch(e => console.log("trackPracticeForUser error", e))
}

export async function getUserPracticeHistory(email, levelId) {
    try {
        //this gets the 3 most recent practice results for the user
        const q = query(
            collection(firestore, `${tableNames.practiceReport}`),
            and(
                or(
                    where("userEmail", "==", `${email}`),
                    where("UserEmail", "==", `${email}`),
                ),
                where("LevelId", "==", levelId),
            ),
            orderBy("timeStamp", 'desc'),
            limit(3))
        const snapshot = await getDocs(q)
        if (snapshot.size === 0) {
            return []
        } else {
            const data = snapshot.docs.map(doc => {
                return {
                    docId: doc.id,
                    ...doc.data(),
                };
            });
            return data
        }
    } catch (e) {
        console.log("getUserPracticeHistory error : ", e)
    }

}


// start getting question data by level id 
export const getQuesDataForPracticeByLevel = (selectedLevel) => {

    return new Promise((resolve, reject) => {
        if (selectedLevel === undefined || selectedLevel === null) {
            resolve([]);
        }
        const q = query(collection(firestore, `${tableNames.question}`), where("LevelId", "==", selectedLevel.LevelId))

        getDocs(q).then(async querySnapshot => {
            let ques = [];
            let count = 0;
            if (querySnapshot !== null) {
                querySnapshot.forEach(async (documentSnapshot) => {
                    const quesitem = documentSnapshot.data();
                    ques.push({
                        id: documentSnapshot.id,
                        ...quesitem,
                        answers: Units.alpha,
                    });
                    count++;
                    if (count === querySnapshot.size) {
                        resolve(ques);
                    }
                });
                if (querySnapshot.size === 0)
                    reject([]);
            } else {
                reject(null);
            }
        }).catch(e => {
            console.log("getQuesDataForPracticeByLevel : ", e)
        })
    });
}

export const getSectionData = (selectedLevel) => {
    return new Promise((resolve, reject) => {
        const q = query(collection(firestore, tableNames.section), where("LevelId", "==", selectedLevel.LevelId))

        getDocs(q).then(querySnapshot => {
            let section = [];
            let count = 0;
            if (querySnapshot !== null) {
                querySnapshot.forEach(async function (documentSnapshot) {
                    const item = documentSnapshot.data();
                    section.push({
                        id: documentSnapshot.id,
                        ...item,
                    });
                    count++;
                    if (count === querySnapshot.size) {
                        const sortedData = section.sort(function (a, b) {
                            return a.SectionNumber - b.SectionNumber;
                        });
                        resolve(sortedData);
                    }
                });
                if (querySnapshot.size === 0) {
                    resolve([]);
                }
            } else {
                reject(null);
            }
        }).catch(e => console.log("getSectionData : ", e))
    });
}

export async function getUserQuestionDataByQuestionId(userEmail, levelId) {
    try {
        //check and get if there is an existing record for the user with the question id
        const q = query(
            collection(firestore, `${tableNames.userQuestionData}`),
            where("Email", "==", `${userEmail}`),
            where("LevelID", "==", levelId));

        const snapshot = await getDocs(q)
        if (snapshot.size === 0) {
            return []
        } else {
            const data = snapshot.docs.map(doc => {
                return {
                    docId: doc.id,
                    ...doc.data(),
                };
            });
            return data
        }
    } catch (e) {
        console.log("getUserQuestionDataByQuestionId : ", e);
        return []
    }

}

export async function getQuestionDataByQuestionId(qNo, levelId) {
    try {
        //check and get if there is an existing record with the question id
        const q = query(
            collection(firestore, `${tableNames.aggregateQuestionData}`),
        )
        const snapshot = await getDocs(q)
        if (snapshot.size === 0) {
            return []
        } else {
            const data = snapshot.docs.map(doc => {
                return {
                    docId: doc.id,
                    ...doc.data(),
                };
            });
            return data
        }
    } catch (e) {
        console.log("getQuestionDataByQuestionId : ", e)
        return []
    }

}

export async function getQuestionDataByLevelId(levelId) {
    try {
        //check and get if there is an existing record with the levelId
        const q = query(
            collection(firestore, `${tableNames.aggregatePracticeTestResults}`))
        const snapshot = await getDocs(q)

        if (snapshot.empty) {
            return []
        } else {
            const data = snapshot.docs.map(doc => {
                return {
                    docId: doc.id,
                    ...doc.data(),
                };
            });
            return data
        }
    } catch (e) {
        return []
        console.log("getQuestionDataByLevelId : ", e)
    }

}

export async function getAllQuestionsData(levelId) {
    try {
        //check and get if there is an existing record with the levelId
        const q = query(
            collection(firestore, `${tableNames.question}`),
            where("LevelId", "==", levelId))
        const snapshot = await getDocs(q)

        if (snapshot.empty) {
            return null
        } else {
            const data = snapshot.docs.map(doc => {
                return {
                    docId: doc.id,
                    ...doc.data(),
                };
            });
            return data
        }
    } catch (e) {
        return undefined
        console.log("getQuestionDataByLevelId : ", e)
    }

}

export async function saveAggregateQuestionData(data) {
    try {
        //check and get if there is an existing record for the user with the question id
        return addDoc(collection(firestore, `${tableNames.aggregatePracticeTestResults}`), data);
    } catch (e) {
        console.log("saveAggregateQuestionData : ", e)
    }

}

export async function updateAggregateQuestionData(docId, data) {
    try {
        //update user question data
        const docRef = doc(firestore, `${tableNames.aggregatePracticeTestResults}`, docId);
        return setDoc(docRef, data, {
            merge: true
        })
    } catch (e) {
        console.log("updateAggregateQuestionData : ", e)
    }

}

export async function saveUserQuestionData(data) {
    try {
        //check and get if there is an existing record for the user with the question id
        return addDoc(collection(firestore, `${tableNames.userQuestionData}`), data);
    } catch (e) {
        console.log("saveUserQuestionData : ", e)
    }

}
export async function updateUserQuestionData(docId, data) {
    try {
        //update user question data
        const docRef = doc(firestore, `${tableNames.userQuestionData}`, docId);
        return setDoc(docRef, data, {
            merge: true
        })
    } catch (e) {
        console.log("updateUserQuestionData : ", e)
    }

}


export async function saveQuestionData(data) {
    try {
        //check and get if there is an existing record for the user with the question id
        return addDoc(collection(firestore, `${tableNames.aggregateQuestionData}`), data);
    } catch (e) {
        console.log("saveQuestionData : ", e)
    }

}
export async function updateQuestionData(docId, data) {
    try {
        //update user question data
        const docRef = doc(firestore, `${tableNames.aggregateQuestionData}`, docId);
        return setDoc(docRef, data, {
            merge: true
        })
    } catch (e) {
        console.log("ERROR updateQuestionData : ", e)
    }

}

export async function getUserUidByEmail(userEmail) {
    const functions = getFunctions();
    
    const getUserUidByEmail = httpsCallable(functions, 'getUserDetails');
  
    try {
      const result = await getUserUidByEmail({ email: userEmail });
      return result.data.uid;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  }