import {
  getUserQuestionDataByQuestionId,
  saveUserQuestionData,
  updateUserQuestionData,
  getQuestionDataByQuestionId,
  saveQuestionData,
  updateQuestionData,
  getQuestionDataByLevelId,
  saveAggregateQuestionData,
  updateAggregateQuestionData
} from "../actions/OfflineAction";
import { Units } from "../constants/Unit";
import { writeBatch, doc, collection } from "firebase/firestore";
import { firestore, tableNames } from "../constants/Config";

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validationEmail = email => {
  return validateEmail(email) ? true : false;
}

export const validationDigit = email => {
  if (validatedigit(email)) {
    return true
  }
  alert("The password should be minimum of 6-digits!");
  return false;
}

export const exchangeElements = (data, index) => {
  var ret = [];
  if (index === 0)
    ret = [data[1], data[0], data[2]];
  else if (index === 1)
    ret = data;
  else if (index === 2)
    ret = [data[0], data[2], data[1]];
  return ret;
}

export const validatedigit = (inputtxt) => {
  var phoneno = /^\d{6}$/;
  if (phoneno.test(String(inputtxt).toLowerCase())) {
    return true;
  } else {
    return false;
  }
}

export const validateNumber = (inputtxt) => {
  var phoneno = /^\d{11}$/;
  if (phoneno.test(String(inputtxt).toLowerCase())) {
    return true;
  } else {
    alert("Phone number is incorrect!");
    return false;
  }
}


export const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const shuffle2 = (array) => {
  let currentIndex = array.length, randomIndex;
  //console.log("CURRENT INDEX START: ", currentIndex);

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
export const getRenderDataForPracticeResult = (ques, section, lid) => {
  // return new Promise ((resolve, reject) => {
  let retData = [];
  const rules = Units.ruleForSelecting[`level${lid}`];
  const secCnt = [rules["section1"], rules["section2"], rules["section3"], rules["section4"], rules["section5"], rules["section6"], rules["section7"], rules["section8"], rules["section9"], rules["section10"]];

  let t = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let qNos = [[], [], [], [], [], [], [], [], [], []]
  let total = 0;
  let tpCnt = 0;
  ques.forEach(quesitem => {
    tpCnt++;
    if (quesitem.Correctanswer === quesitem.userAnswer || quesitem.CorrectAnswer === quesitem.userAnswer) {
      total++;
      const insertItem = {
        status: 1,
        QNo: quesitem.QNo
      }
      if (quesitem.SectionId === section[0].SectionId) {
        t[0]++;
        qNos[0].push(insertItem);
      }
      if (quesitem.SectionId === section[1].SectionId) {
        t[1]++;
        qNos[1].push(insertItem);
      }
      if (quesitem.SectionId === section[2].SectionId) {
        t[2]++;
        qNos[2].push(insertItem);
      }
      if (quesitem.SectionId === section[3].SectionId) {
        t[3]++;
        qNos[3].push(insertItem);
      }
      if (quesitem.SectionId === section[4].SectionId) {
        t[4]++;
        qNos[4].push(insertItem);
      }
      if (quesitem.SectionId === section[5].SectionId) {
        t[5]++;
        qNos[5].push(insertItem);
      }
      if (quesitem.SectionId === section[6].SectionId) {
        t[6]++;
        qNos[6].push(insertItem);
      }
      if (quesitem.SectionId === section[7].SectionId) {
        t[7]++;
        qNos[7].push(insertItem);
      }
      if (quesitem.SectionId === section[8].SectionId) {
        t[8]++;
        qNos[8].push(insertItem);
      }
      if (quesitem.SectionId === section[9].SectionId) {
        t[9]++;
        qNos[9].push(insertItem);
      }
    } else {
      const insetItem = {
        status: 0,
        QNo: quesitem.QNo
      };
      if (quesitem.SectionId === section[0].SectionId) {
        qNos[0].push(insetItem);
      }
      if (quesitem.SectionId === section[1].SectionId) {
        qNos[1].push(insetItem);
      }
      if (quesitem.SectionId === section[2].SectionId) {
        qNos[2].push(insetItem);
      }
      if (quesitem.SectionId === section[3].SectionId) {
        qNos[3].push(insetItem);
      }
      if (quesitem.SectionId === section[4].SectionId) {
        qNos[4].push(insetItem);
      }
      if (quesitem.SectionId === section[5].SectionId) {
        qNos[5].push(insetItem);
      }
      if (quesitem.SectionId === section[6].SectionId) {
        qNos[6].push(insetItem);
      }
      if (quesitem.SectionId === section[7].SectionId) {
        qNos[7].push(insetItem);
      }
      if (quesitem.SectionId === section[8].SectionId) {
        qNos[8].push(insetItem);
      }
      if (quesitem.SectionId === section[9].SectionId) {
        qNos[9].push(insetItem);
      }
    }
  });
  if (tpCnt === ques.length) {
    let count = 0;
    t.forEach((cnt, index) => {
      retData.push({
        name: "Lesson " + (count + 1),
        totalCnt: secCnt[count],
        correctCnt: cnt,
        percent: parseInt(100 * cnt / secCnt[count]),
        SectionId: section[index].SectionId,
        questionNo: qNos[index]
      });
      count++;
    });
    return {
      total: total,
      data: retData,
    };
  }
  // })
}

const strMonths = ["Jan", "Feb", "Mar", "Apl", "May", "Jun", "Jly", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const getCurrentDayTime = () => {
  var date = new Date();
  var day = date.getDate();
  var mon = strMonths[date.getMonth()];
  var y = date.getFullYear();
  var h = date.getHours();
  var m = date.getMinutes();
  return mon + " " + day + ", " + y + " " + h + ":" + m + formatAMPM(date);
}

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var ampm = hours >= 12 ? 'pm' : 'am';
  return ampm;
}

export const getAveScore = (arr) => {
  if (arr === null) return 0;
  if (arr.length === 0) return 0;
  const division = arr.length > 3 ? 3 : arr.length;
  let score = 0;
  for (let i = 0; i < 3; i++) {
    if (i < arr.length)
      score += arr[i].Score;
  }

  return parseInt(score / division);
}

export const getPassedCnt = (arr) => {
  if (arr === null) return 0;
  if (arr.length === 0) return 0;
  const temp = arr.filter((_) => _.Pass === 1);
  return temp.length;
}

export function getAverageOfArrayWithParameter(array, parameter) {
  let sum = null;
  if (array && array.length > 0 && typeof parameter === 'string') {
    sum = 0;
    for (let e of array) if (e && e.hasOwnProperty(parameter)) sum += e[parameter];
  }
  return sum / array.length;
}

//Save users results to the needed collections(aggregate and user scores)
export async function registerUserQuestionData(data, userEmail) {
  try {
    if (!data || !data.length) throw new Error("No data to save");
    let userData = null
    //Define a batch object
    const batch = writeBatch(firestore);
    const [firstContact] = data;
    const allUserQuestionData = await getUserQuestionDataByQuestionId(userEmail, firstContact.LevelId)
    for (let i = 0; i < data.length; i++) {
      const questionData = data[i]
      //check to see if a question exists for the user
      const existingQuestion = allUserQuestionData.filter((q) => q.QNo === questionData.QNo);
      //if the question exists get the current value and update it if not create a new record with the question number and user email
      if (existingQuestion && existingQuestion[0]) {
        const userQuestion = existingQuestion[0]
        userData = {
          Email: userEmail,
          LastAttemptCorrect: questionData.userAnswer === questionData.Correctanswer ? true : false,
          LastSeen: `${new Date()}`,
          LevelID: questionData.LevelId,
          NumTimesAnsA: questionData.userAnswer === "A" ? userQuestion.NumTimesAnsA + 1 : userQuestion.NumTimesAnsA,
          NumTimesAnsB: questionData.userAnswer === "B" ? userQuestion.NumTimesAnsB + 1 : userQuestion.NumTimesAnsB,
          NumTimesAnsC: questionData.userAnswer === "C" ? userQuestion.NumTimesAnsC + 1 : userQuestion.NumTimesAnsC,
          NumTimesAnsD: questionData.userAnswer === "D" ? userQuestion.NumTimesAnsD + 1 : userQuestion.NumTimesAnsD,
          NumTimesAttempted: userQuestion.NumTimesAttempted + 1,
          NumTimesCorrect: questionData.userAnswer === questionData.Correctanswer ? userQuestion.NumTimesCorrect + 1 : userQuestion.NumTimesCorrect,
          NumTimesMissed: questionData.userAnswer === questionData.Correctanswer ? userQuestion.NumTimesMissed : userQuestion.NumTimesMissed + 1,
          QNo: questionData.QNo,
          SectionID: questionData.SectionId,
          CustomLessonID: questionData.CustomLessonID,
          CustomTopicID: questionData.CustomTopicID,
          Platform: "Course",
          AppPlatform: "web",
        }
        //question already exists: get the document reference and add it to a batch for execution
        const docRef = doc(firestore, `${tableNames.userQuestionData}`, `${userQuestion.docId}`);
        batch.update(docRef, userData);
      } else {
        userData = {
          Email: userEmail,
          LastAttemptCorrect: questionData.userAnswer === questionData.Correctanswer ? true : false,
          LastSeen: `${new Date()}`,
          LevelID: questionData.LevelId,
          NumTimesAnsA: questionData.userAnswer === "A" ? 1 : 0,
          NumTimesAnsB: questionData.userAnswer === "B" ? 1 : 0,
          NumTimesAnsC: questionData.userAnswer === "C" ? 1 : 0,
          NumTimesAnsD: questionData.userAnswer === "D" ? 1 : 0,
          NumTimesAttempted: 1,
          NumTimesCorrect: questionData.userAnswer === questionData.Correctanswer ? 1 : 0,
          NumTimesMissed: questionData.userAnswer === questionData.Correctanswer ? 0 : 1,
          QNo: questionData.QNo,
          SectionID: questionData.SectionId,
          CustomLessonID: questionData.CustomLessonID,
          CustomTopicID: questionData.CustomTopicID,
          Platform: "Course",
          AppPlatform: "web",
        }
        //question doesnt exists: create a new document reference and add it to a batch for execution
        const doRef = doc(collection(firestore, `${tableNames.userQuestionData}`))
        batch.set(doRef, userData);
      }
    }

    //and finally commit the batch to apply the changes
    await batch.commit();
    console.log("USER QUESTION DATA SAVED SUCCESSFULLY");
  } catch (e) {
    console.log("USER QUESTION DATA ERROR : ", e)
  }

}

export async function registerAggregateQuestionData(data) {
  try {
    let userData = null
    const batch = writeBatch(firestore);

    const allAggregateData = await getQuestionDataByQuestionId()
    for (let i = 0; i < data.length; i++) {
      const questionData = data[i]
      const existingQuestion = allAggregateData.filter((ques) => ques.QNo === questionData.QNo)
      let userQuestion = existingQuestion
      if (existingQuestion && existingQuestion.length) {
        userQuestion = existingQuestion[0]
      } else {
        userQuestion = {
          NumTimesAnsA: 0,
          NumTimesAnsB: 0,
          NumTimesAnsC: 0,
          NumTimesAnsD: 0,
          NumTimesAttempted: 0,
          NumTimesCorrect: 0,
          NumTimesMissed: 0,
        }
      }
      // if (existingQuestion && existingQuestion[0]) {
      //   const userQuestion = existingQuestion[0]
      userData = {
        LevelID: questionData.LevelId,
        NumTimesAnsA: questionData.userAnswer === "A" ? userQuestion.NumTimesAnsA + 1 : userQuestion.NumTimesAnsA,
        NumTimesAnsB: questionData.userAnswer === "B" ? userQuestion.NumTimesAnsB + 1 : userQuestion.NumTimesAnsB,
        NumTimesAnsC: questionData.userAnswer === "C" ? userQuestion.NumTimesAnsC + 1 : userQuestion.NumTimesAnsC,
        NumTimesAnsD: questionData.userAnswer === "D" ? userQuestion.NumTimesAnsD + 1 : userQuestion.NumTimesAnsD,
        NumTimesAttempted: userQuestion.NumTimesAttempted + 1,
        NumTimesCorrect: questionData.userAnswer === questionData.Correctanswer ? userQuestion.NumTimesCorrect + 1 : userQuestion.NumTimesCorrect,
        NumTimesMissed: questionData.userAnswer === questionData.Correctanswer ? userQuestion.NumTimesMissed : userQuestion.NumTimesMissed + 1,
        QNo: questionData.QNo,
        SectionID: questionData.SectionId,
        Platform: "Course"
      }
      const docRef = doc(firestore, `${tableNames.aggregateQuestionData}`, `${questionData.QNo}`);

      if (existingQuestion && existingQuestion.length) {
        batch.update(docRef, userData);
      } else {
        batch.set(docRef, userData);
      }
      // } else {
      //   userData = {
      //     LevelID: questionData.LevelId,
      //     NumTimesAnsA: questionData.userAnswer === "A" ? 1 : 0,
      //     NumTimesAnsB: questionData.userAnswer === "B" ? 1 : 0,
      //     NumTimesAnsC: questionData.userAnswer === "C" ? 1 : 0,
      //     NumTimesAnsD: questionData.userAnswer === "D" ? 1 : 0,
      //     NumTimesAttempted: 1,
      //     NumTimesCorrect: questionData.userAnswer === questionData.Correctanswer ? 1 : 0,
      //     NumTimesMissed: questionData.userAnswer === questionData.Correctanswer ? 0 : 1,
      //     QNo: questionData.QNo,
      //     SectionID: questionData.SectionId,
      //     Platform: "Course"
      //   }
      //   const doRef = doc(collection(firestore, `${tableNames.aggregateQuestionData}`))
      //   batch.set(doRef, userData);
      // }


    }
    batch.commit();
  } catch (e) {
    console.log("AGGREGATE QUESTION DATA ERROR : ", e)
  }
}

export async function registerAggregatePractice(data, level, score) {
  let userData = null
  const allExistingData = await getQuestionDataByLevelId(level.LevelId)
  let totalQuestionsCorrect = data.filter((ques) => ques.userAnswer === (ques.CorrectAnswer || ques.Correctanswer)).length
  const existingData = allExistingData.filter((ques) => ques.LevelID === level.LevelId)
  if (existingData && existingData.length) {
    const aggregateData = existingData[0]
    totalQuestionsCorrect = aggregateData.TotalNumQuestionsCorrect + totalQuestionsCorrect
    const totalAttempted = data.length + aggregateData.TotalQuestionsAttempted
    userData = {
      AverageScore: totalQuestionsCorrect === 0 ? 0 : (totalQuestionsCorrect / totalAttempted) * 100,
      LevelID: level.LevelId,
      NumTimesAttempted: aggregateData.NumTimesAttempted + 1,
      NumTimesFailed: score < Units.passPercent ? aggregateData.NumTimesFailed + 1 : aggregateData.NumTimesFailed,
      NumTimesPassed: score < Units.passPercent ? aggregateData.NumTimesPassed : aggregateData.NumTimesPassed + 1,
      Platform: "Course",
      TotalNumQuestionsCorrect: totalQuestionsCorrect,
      TotalNumQuestionsMissed: aggregateData.TotalNumQuestionsMissed + data.filter((ques) => ques.userAnswer !== (ques.CorrectAnswer || ques.Correctanswer)).length,
      TotalQuestionsAttempted: totalAttempted
    }
    updateAggregateQuestionData(`Level-${level.LevelId}`, userData)
  } else {
    userData = {
      AverageScore: totalQuestionsCorrect === 0 ? 0 : (totalQuestionsCorrect / data.length) * 100,
      LevelID: level.LevelId,
      NumTimesAttempted: 1,
      NumTimesFailed: score < Units.passPercent ? 1 : 0,
      NumTimesPassed: score < Units.passPercent ? 0 : 1,
      Platform: "Course",
      TotalNumQuestionsCorrect: totalQuestionsCorrect,
      TotalNumQuestionsMissed: data.filter((ques) => ques.userAnswer !== (ques.CorrectAnswer || ques.Correctanswer)).length,
      TotalQuestionsAttempted: data.length
    }
    // saveAggregateQuestionData(userData)
    updateAggregateQuestionData(`Level-${level.LevelId}`, userData)

  }
}