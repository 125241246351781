import { t } from '../actions/Type';

const initialState = {
    resloading: false,
    practiceRes: [],
    premiumRes: [],
    score: 0,
    data: {},
    sessionId: 0,
    userEmail: null
}

export default function (state = initialState, action) {

    switch (action.type) {
        case t.LOAD_PRACTICE_RESULT:
            return {
                ...state,
                practiceRes: action.payload,
            };
        case t.LOAD_START:
            return {
                ...state,
                practiceRes: [],
            }
        case t.SET_USER_CLICKED_ON_PREMIUM:
            return {
                ...state,
                premiumRes: action.payload,
            }
        case t.SET_SCORE:
            return {
                ...state,
                score: action.payload,
            }
        case t.SET_RESULT_DATA:
            return {
                ...state,
                data: action.payload,
            }
        case t.SET_SESSION_ID:
            return {
                ...state,
                sessionId: action.payload,
            }
        case t.SET_USER_EMAIL:
            return {
                ...state,
                userEmail: action.payload,
            }
        default:
            return state;
    }
}