import { Units } from '../constants/Unit';
import { t } from './Type';

export function getLevel(data) {
    return (dispatch) => {
        dispatch({
            type: t.LEVEL_LOAD_SUCCESS,
            payload: data,
        });
    }
}

export function selectOneLevel(data) {
    return (dispatch) => {
        dispatch({
            type: t.LEVEL_ONE_SELECT,
            payload: data,
        });
    }
}

export function setScore(data) {
    return (dispatch) => {
        dispatch({
            type: t.SET_SCORE,
            payload: data,
        });
    }
}

export function setSessionId(data) {
    return (dispatch) => {
        dispatch({
            type: t.SET_SESSION_ID,
            payload: data,
        });
    }
}

export function setUserEmail(data) {
    return (dispatch) => {
        dispatch({
            type: t.SET_USER_EMAIL,
            payload: data,
        });
    }
}

export function setResultData(data) {
    return (dispatch) => {
        dispatch({
            type: t.SET_RESULT_DATA,
            payload: data,
        });
    }
}

export function getSection(data) {
    return (dispatch) => {
        dispatch({
            type: t.SECTION_LOAD_SUCCESS,
            payload: data,
        });
    }
}
export function selectOneSection(data) {
    return (dispatch) => {
        dispatch({
            type: t.SECTION_ONE_SELECT,
            payload: data,
        });
    }
}

export function setReportsForLevel(data) {
    return (dispatch) => {

        dispatch({
            type: t.REPORTS_LEVEL_LOAD,
            payload: data,
        });
    }
}

export function setQuesData(data) {
    return (dispatch) => {
        dispatch({
            type: t.QUESTION_DATA_LOAD,
            payload: data,
            quizType: "general",
        });
    }
}

export function setResultsForQuestion(data) {
    return (dispatch) => {
        dispatch({
            type: t.REPORTS_QUESTION_LOAD,
            payload: data,
        });
    }
}
export function getQuestionForPractice(data) {
    return async (dispatch) => {
        dispatch({
            type: t.QUESTION_DATA_LOAD,
            payload: data,
            quizType: "practice",
        });
    }
}

export function getQuesBySection(origin, section, lid) {
    return new Promise((resolve, reject) => {

        let retData = [];
        const rules = Units.ruleForSelecting[`level${lid}`];
        const sec1Cnt = rules["section1"];
        const sec2Cnt = rules["section2"];
        const sec3Cnt = rules["section3"];
        const sec4Cnt = rules["section4"];
        const sec5Cnt = rules["section5"];
        const sec6Cnt = rules["section6"];
        const sec7Cnt = rules["section7"];
        const sec8Cnt = rules["section8"];
        const sec9Cnt = rules["section9"];
        const sec10Cnt = rules["section10"];

        let t1 = 0, t2 = 0, t3 = 0, t4 = 0, t5 = 0, t6 = 0, t7 = 0, t8 = 0, t9 = 0, t10 = 0;

        origin.forEach((quesitem) => {
            if (quesitem.SectionId === section[0].SectionId && t1 < sec1Cnt) {
                retData.push(quesitem);
                t1++;
            }
            if (quesitem.SectionId === section[1].SectionId && t2 < sec2Cnt) {
                retData.push(quesitem);
                t2++;
            }
            if (quesitem.SectionId === section[2].SectionId && t3 < sec3Cnt) {
                retData.push(quesitem);
                t3++;
            }
            if (quesitem.SectionId === section[3].SectionId && t4 < sec4Cnt) {
                retData.push(quesitem);
                t4++;
            }
            if (quesitem.SectionId === section[4].SectionId && t5 < sec5Cnt) {
                retData.push(quesitem);
                t5++;
            }
            if (quesitem.SectionId === section[5].SectionId && t6 < sec6Cnt) {
                retData.push(quesitem);
                t6++;
            }
            if (quesitem.SectionId === section[6].SectionId && t7 < sec7Cnt) {
                retData.push(quesitem);
                t7++;
            }
            if (quesitem.SectionId === section[7].SectionId && t8 < sec8Cnt) {
                retData.push(quesitem);
                t8++;
            }
            if (quesitem.SectionId === section[8].SectionId && t9 < sec9Cnt) {
                retData.push(quesitem);
                t9++;
            }
            if (quesitem.SectionId === section[9].SectionId && t10 < sec10Cnt) {
                retData.push(quesitem);
                t10++;
            }
            if (t1 === sec1Cnt && t2 === sec2Cnt && t3 === sec3Cnt && t4 === sec4Cnt && t5 === sec5Cnt && t6 === sec6Cnt && t7 === sec7Cnt && t8 === sec8Cnt && t9 === sec9Cnt && t10 === sec10Cnt) {

                resolve(retData);
            }
        });
    });
}

export function ReviewData(data) {
    return (dispatch) => {
        dispatch({
            type: t.SET_REVIEW_DATA,
            payload: data,
        })
    }
}