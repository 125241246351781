import React from "react";
import { StyleSheet, Text, View } from 'react-native';
import { GradientCircularProgress } from 'react-native-circular-gradient-progress';
import { EDColors } from "../constants/Colors";
import { Units } from "../constants/Unit";

const CircularProgressComponent = ({ size, width, font, fill, color }) => {
    return (
        <GradientCircularProgress
            size={size}
            startColor={color || EDColors.gradient[2]}
            middleColor={color || EDColors.gradient[2]}
            endColor={color || EDColors.gradient[2]}
            progress={fill}
            emptyColor={EDColors.lightgrey}
            strokeWidth={width}
            children={<View style={style.titleBox}>
                <Text style={[style.title, { fontSize: font }]}>{parseInt(fill)}%</Text>
            </View>}
        />
    );
}

const style = StyleSheet.create({
    titleBox: {
        ...StyleSheet.absoluteFillObject,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: Units.ftFamily.KarlaR,
        color: EDColors.text,
        textAlign: 'center',
    },
});
export default CircularProgressComponent;