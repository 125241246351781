
export const t = {
    AUTH_LOAD_SUCCESS: "AUTH_LOAD_SUCCESS",
    LEVEL_LOAD: "LEVEL_LOAD",
    LEVEL_LOAD_SUCCESS: "LEVEL_LOAD_SUCCESS",
    LEVEL_LOAD_FAILED: "LEVEL_LOAD_FAILED",
    LEVEL_ONE_SELECT: "LEVEL_ONE_SELECT",
    SECTION_LOAD_SUCCESS: "SECTION_LOAD_SUCCESS",
    SECTION_ONE_SELECT: "SECTION_ONE_SELECT",
    REPORTS_LEVEL_LOAD: "REPORTS_LEVEL_LOAD",
    QUESTION_DATA_LOAD: "QUESTION_DATA_LOAD",
    REPORTS_QUESTION_LOAD: "REPORTS_QUESTION_LOAD",
    REPORTS_PRACTICE_LEVEL_LOAD: "REPORTS_PRACTICE_LEVEL_LOAD",
    SET_REVIEW_DATA: "SET_REVIEW_DATA",
    QUESTION_LOAD_START: "QUESTION_LOAD_START",

    LOAD_PRACTICE_RESULT: "LOAD_PRACTICE_RESULT",
    LOAD_START: "LOAD_START",
    SET_USER_CLICKED_ON_PREMIUM: "SET_USER_CLICKED_ON_PREMIUM",

    NETWORK_CONNECTED_STATE: "NETWORK_CONNECTED_STATE",
    SEND_IN_APP_REVIEW: "SEND_IN_APP_REVIEW",
    OFFLINE_FILES: "OFFLINE_FILES",
    SET_SCORE: "SET_SCORE",
    SET_RESULT_DATA: "SET_RESULT_DATA",
    SET_SESSION_ID: "SET_SESSION_ID",

    SET_USER_EMAIL: "SET_USER_EMAIL",


}