import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    ActivityIndicator,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';

import { useMediaQuery } from 'react-responsive';

import styles from '../screens/examReadiness/style';

import { Units } from '../constants/Unit';
import { EDColors } from '../constants/Colors';
import CircularProgressComponent from './CircularProgressComponent';

const ExamReadinessStat = ({ title, subtitle, progress, small, textColor, dialColor }) => {
    const isMobile = useMediaQuery({
        query: "(max-width: 680px)",
    });
    return (

        <View style={styles.horizontalContainer}>
            <View>
                <Text style={[small ? styles.txt7 : isMobile ? styles.txt9 : styles.txt4, textColor ? { color: textColor } : {}]}>
                    {title}
                </Text>
                <Text style={small ? styles.txt8 : styles.txt5}>
                    {subtitle}
                </Text>
            </View>
            <View style={[styles.progressContainer, isMobile ? { width: 100 } : { width: 200 }]}>
                <CircularProgressComponent
                    size={Units.isTablet ? 150 : Units.isIOS ? 100 : small ? isMobile ? 80 : 110 : isMobile ? 100 : 150}
                    font={Units.ftSizes.extra}
                    fill={progress}
                    color={dialColor || EDColors.blue}
                />
            </View>
        </View>
    );

};

export default ExamReadinessStat;
