import { initializeApp } from 'firebase/app';
import { getFirestore, initializeFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  REACT_APP_API_KEY, REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL, REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET, REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID, REACT_APP_MEASUREMENT_ID
} from '@env';


export const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
// let Firebase;

// if (firebase.apps.length === 0) {
//   Firebase = firebase.initializeApp(firebaseConfig);
// }
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const firestore = initializeFirestore(app, { experimentalForceLongPolling: true });
export const tableNames = {
  level: 'Levels-Web',
  question: 'Questions',
  section: 'Sections',
  practiceReport: 'PracticeForReportsForUser',
  userQuestionData: 'UserQuestionData',
  aggregateQuestionData: 'AggregateQuestionDataV2',
  aggregatePracticeTestResults: 'AggregatePracticeTestResultsV2'
}

export const authInfo = {
  email: 'practicetestuser@americanradioclub.com',
  password: 'practicetestuser'
}

// export default Firebase;
