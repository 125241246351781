export const EDColors = {
    topbar: "#000d3f",
    white: "#FFFFFF",
    black: "#000000",
    pgbar: "#00cbd6",
    pgbarbk: "#4d577b",
    borderBtn: "#000d3f",

    primary: "#00f5dc",
    secondary: '#00f5dc',
    third: '#00aad5',
    fourth: '#FFFFFF',
    lightGray: '#ebecf0',
    lightBlue: '#00aad5',
    text: '#000000',
    passText: '#5F795B',
    progress: ["#00aad5", "#00d1d9", "#00f5dc"],
    progress2: ["#00aad5", "#00d1d9", "#00f5dc"],
    textGreen: "#219653",
    itembdr: "#cbced8",
    green: "#00b674",
    blue: "#1b05a8",
    brightblue: "#1b05a8",
    bggreen: "#c0fdf3",
    lightgreen: "#27ae6021",
    brightgreen: "#6FCF97",
    red: "#d12223",
    errorred: "#C11632",
    lightred: "#FDEEEE",
    bgred: "#fff5f6",
    unselect: "#ffe2e1",
    fbbk: "#0049fd",
    weeklightgrey: "#f2f3f5",
    trans: "transparent",
    acttoggle: "#00f6db",
    tint: "#0496c1",
    grey: "grey",
    lightgrey: "lightgrey",
    descback: "#064667",
    reviewbk: "#c9f9f7",
    poolbk: "#ebf5fb",
    poolitem: "#f5fafd",
    splashbk: "#010a41",
    underline: "blue",
    placeclr: "rgba(0, 200, 200, .5)",
    warning: "rgba(200, 10, 20, 1)",
    sepline: "rgba(0, 187, 212, .3)",
    modalbk: "rgba(0, 0, 0, .3)",
    gradient: ["#00aad5", "#00d1d9", "#00f5dc"],
    yellow: "#E8A317"
}