import React, { useState } from 'react';
import {
  View,
  Text,
  Image,
} from 'react-native';
import { useSelector } from 'react-redux';
import { FlatList } from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';

import styles from './style';
import { TopBar, } from '../../components';
import { Units } from '../../constants/Unit';

import { Assets } from '../../constants/Images';
import { EDColors } from '../../constants/Colors';

const alpha = Units.alpha;
const staticImgs = Assets.staticImgs;

const Review = ({ history }) => {
  const { selectedLevel, reviewData } = useSelector(state => state.LevelReducer);

  const renderQuesData = reviewData === null ? [] : reviewData;

  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  const getDownloadImageUrl = (name) => {
    //get download url for questions with images from storage
    if (name) {
      const nm = name.split('.');
      return staticImgs[nm[0]];
    } else {
      return staticImgs.T1;
    }
  }

  const _handleFlag = () => {
    // show the modal for webhooks
    // setHookModalVisible(true);
  }

  const _renderItem = (quesitem, itemIdx) => {
    return alpha.map((item, index) => {
      let clr = EDColors.itembdr;
      let bgClr = EDColors.white;
      const bGreen = (item === quesitem.CorrectAnswer || item === quesitem.Correctanswer);
      if (bGreen) {
        clr = EDColors.green;
        bgClr = EDColors.bggreen;
      }
      else if (item === quesitem.userAnswer) {
        clr = EDColors.red;
        bgClr = EDColors.bgred;
      }
      return (
        <View style={[styles.answerCon, { backgroundColor: bgClr, borderColor: clr }]} key={item + "" + index.toString()}>
          <View style={styles.optionCon}>
            <View style={styles.circle}>
              {item === quesitem.userAnswer && <View style={styles.innerCircle}></View>}
            </View>
          </View>
          <View style={styles.letterCon}>
            <Text style={styles.indexTxt}>{item}</Text>
          </View>
          <View style={isMobile ? styles.ansTxtConMobile : styles.ansTxtCon}>
            <Text style={styles.quesTxt2}>{quesitem[`Answer${item}`]}</Text>
          </View>
        </View>
      );
    })

  }

  const _mainRenderItem = ({ item, index }) => {
    let img = "";
    if (item.IsImageQuestion === "Y") {
      img = getDownloadImageUrl(item.ImageUrl === undefined ? item.imageName : item.ImageUrl)
    }
    return (
      <View style={{ marginHorizontal: 5 }}>
        <View style={styles.titleContainer}>
          {/* <View>
            <Text style={styles.question}>Question {index+1}: </Text>
            <Text style = {styles.noText}>{`(${item.QNo})`}</Text>
          </View> */}
          <Text style={styles.quesTxt}><Text style={styles.question}>Question {index + 1} <Text style={styles.question}>{`(${item.QNo})`}</Text>: </Text>{item.Question}</Text>
        </View>
        {item.IsImageQuestion === "Y" && img &&
          <Image source={img} style={styles.imgQues} resizeMode="contain" />}
        {_renderItem(item, index)}
        <Text style={styles.quesTxt2}><Text style={styles.indexTxt}>Explanation: </Text>{item.Help}</Text>
        <View style={{ height: 20 }}></View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <TopBar
          title={selectedLevel === null || selectedLevel === undefined ? "" : `${selectedLevel.Name}`}
          subtitle="Practice Test"
          history={history}

        // flag = "flag-outline"
        // onRightPress={_handleFlag}
        />
        <View style={styles.progressCon}>
          {/* <LinearlineProgress 
              progress = {progress}
              gradient={EDColors.gradient}
              width={5}
              background = {EDColors.grey}
          /> */}
        </View>
      </View>
      <View style={styles.contentContainer}>

        <View style={isMobile ? [styles.body, { paddingHorizontal: '5%' }] : [styles.body]} >
          <FlatList
            data={renderQuesData}
            keyExtractor={(item) => item.id}
            renderItem={_mainRenderItem}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>
      {/* {otherModalVisible&& <View style={{position: "absolute", flex: 1, backgroundColor: "#000", width: "100%", height: "100%", opacity: .5}}></View>}
      {otherModalVisible&&<View style={styles.otherModalCon}>
          <OtherModal
              modalVisible = {otherModalVisible}
              onClose = {()=>setOtherModalVisible(false)}
              onSubmit = {(txt) => handleWebhook(txt)}
          />
      </View>}
      <WebhookModal
          data = {Units.webhookData}
          modalVisible={hookModalVisible}
          itemPress={(txt) => handleWebhook(txt)}
          onClose = {()=>setHookModalVisible(false)}
      /> */}
      <View style={{ height: 40 }} />
    </View>
  );

};

export default Review;
