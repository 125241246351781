import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  Linking,
  ScrollView,
  StyleSheet,
  Dimensions,

} from 'react-native';
import MultiSwitch from 'react-multi-switch-toggle'
import { FlatList } from 'react-native-gesture-handler';
import { useMediaQuery } from 'react-responsive';

import { selectOneLevel, setUserEmail } from '../../actions/LevelAction';
import { useAction } from '../../actions/useAction';
import { GradientButton4, ScreenHeader } from '../../components';
import { Assets } from '../../constants/Images';
import styles from './style';
import { EDColors } from '../../constants/Colors';

import { getLevel } from '../../actions/OfflineAction';
import { authInfo, auth } from '../../constants/Config';
import { Units } from '../../constants/Unit';
import { signInWithEmailAndPassword } from 'firebase/auth';
import StudyLevels from '../../components/StudyLevels';
import Toast from 'react-native-toast-message'

const width = Dimensions.get('screen').width

const imgs = [Assets.technician, Assets.general, Assets.amateur, Assets.beofeng];
const subUrls = ["technician", "general", "extra"];

const Study = ({ navigation, history, location }) => {
  const [renderData, setRenderData] = useState([]);
  const [curIndex, setCurrentIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [bOneItem, setOneItem] = useState(true);

  const selectLevel = useAction(selectOneLevel);
  const saveUserEmail = useAction(setUserEmail);


  const isMobile = useMediaQuery({
    query: "(max-width: 520px)",
  });
  /*
    const indexedDB = window.indexedDB;
    if (!indexedDB) {
      console.log("IndexedDB could not be found in this browser.");
    } else {
      const request = indexedDB.open("firebaseLocalStorageDb", 1);
      request.onsuccess = function (event) {
        const db = request.result;
        console.log("connected to DB", db)
      }
    }
  */

  useEffect(() => {
    //log users in with default accounts so that firebase doesnt block the requests
    signInWithEmailAndPassword(auth, authInfo.email, authInfo.password).then((user) => {
      if (user.user) {
        checkUrl();
      }
    }).catch((err) => {
    })
  }, [])

  const checkUrl = () => {
    //gets the initial url and parses the level id to fetch the questions
    const url = location.pathname
    const lastItem = url.substring(url.lastIndexOf('/') + 1);
    let index = -1;
    if (lastItem === subUrls[0]) {
      index = 0;
    } else if (lastItem === subUrls[1]) {
      index = 1;
    } else if (lastItem === subUrls[2]) {
      index = 2;
    }
    if (index !== -1) {
      setCurrentIndex(index);
      getLevelData(index);
      setOneItem(false);
    } else {
      if (lastItem.includes('.') || lastItem === "") {
        setCurrentIndex(0);
        setOneItem(true);
        getLevelData(0);
      } else {
        setLoading(false);
        alert("Invalid Url!" + '\n' + "Please use like this https://.../#/technician, general, extra");
      }
    }
  }

  const getLevelData = async (_idx) => {
    //get all available levels, and select the first level by default, the index gets passed from checkURL: 
    //if users passed the level on the url we use that if not we select the first one by default
    var networkTimer = setTimeout(() => {
      Toast.show({
        type: 'error',
        text1: 'Taking too long? Tap here to reload',
        text2: 'There seems to be a delay connecting to our servers.',
        autoHide: false,
        onPress: () => {
          history.go(0)
        }
      });
    }, 11000);
    getLevel().then((level) => {
      clearTimeout(networkTimer)
      setData(level, _idx);
    }).catch((err) => {
      clearTimeout(networkTimer)
      alert("Please login or check your network status.\n" + err);
      // navigation.pop();
    });
  }

  const setData = (arr, _idx) => {
    setRenderData([...arr, {
      LevelId: 5,
      Name: "General License",
      ShortDesc: "Prerequisite: Technician",
      Status: true,
      id: "379cb8fa-daa9-11ed-afa1-0242ac120002"
    },
    {
      LevelId: 6,
      Name: "Amateur Extra License",
      ShortDesc: "Prerequisite: General",
      Status: true,
      id: "379cb8fa-daa9-11ed-afa1-0823uy87223"
    }
    ]);
    if (_idx == 0)
      selectLevel(arr[3]);
    else
      selectLevel(arr[_idx]);
    setLoading(false);
  }

  const _handleItem = () => {
    //handles taking the user to the exam page after user click on start exam
    saveUserEmail(null)
    history.push("/Answer");
  }

  const _handleSelectItem = (index) => {
    console.log("renderData : ", renderData)
    //handles level tab changes and sets the selected level
    setCurrentIndex(index);
    if (index == 0) {
      selectLevel(renderData[3]);
    }
    else {
      selectLevel(renderData[index]);
    }
  }

  const _renderItem = ({ item, index }) => {
    if (item === null || item === undefined) {
      return null;
    }
    if (index > 2) // This is so that the "ghost" levels are not displayed
      return;
    return (
      <TouchableOpacity style={index === curIndex ? styles.actLevelitemCon : styles.levelitemCon} onPress={() => _handleSelectItem(index)} key={item.id}>
        <Text style={index === curIndex ? styles.levelTxt : styles.actlevelTxt}>Level {index + 1}</Text>
      </TouchableOpacity>
    );
  }

  const toggleSwitch = (selectedItem) => {
    //console.log(selectedItem);
    //console.log(isEnabled);
    if (selectedItem) { // If the toggle switch is on the new test option, we set the level to be '4'
      _handleSelectItem(3);
    }
    else {
      _handleSelectItem(0); // Otherwise stay on level '1'
    }
    setCurrentIndex(0); // Set the index back to 0 so that visually levels 1 and 4 look the same
  }
  return (
    <View style={styles.container}>
      <View style={styles.mainbody}>
        <ScreenHeader text={isMobile && !bOneItem ? "" : "Amateur Radio License Practice Test"} isMobile={isMobile} />
        {curIndex !== -1 && <View style={styles.body}>
          {loading || renderData.length === 0 ?
            <View style={{ backgroundColor: EDColors.topbar }}>
              <ActivityIndicator size="large" color={EDColors.primary} />
              <View style={{ height: 1000 }} />
            </View>
            :
            <>
              <ScrollView contentContainerStyle={{
                paddingHorizontal: 15,
                alignItems: "center",
              }}>
                <StudyLevels
                  imgs={imgs}
                  levelId={1}
                  info="The first and most basic license. Beginners start here!"
                  fill={30}
                  title="Technician License"
                  subTitle="(2022 - 2026)"
                  imgIndex={0}
                  onPress={() => {
                    _handleItem(3, 0)
                    _handleSelectItem(0)
                  }}
                />
                {/* <View style={styles.separator} />
                <StudyLevels
                  imgs={imgs}
                  levelId={2}
                  info="Use this pool if taking the exam before July 1st, 2023"
                  fill={30}
                  title="General License"
                  subTitle="(2019 - 2023)"
                  imgIndex={1}
                  onPress={() => {
                    _handleItem(1, 1)
                    _handleSelectItem(1)
                  }}
                /> */}
                <View style={styles.separator} />
                <StudyLevels
                  imgs={imgs}
                  levelId={2}
                  info="Use this pool if taking the exam on or after July 1st, 2023"
                  fill={30}
                  title="General License"
                  subTitle="(2023 - 2027)"
                  imgIndex={1}
                  onPress={() => {
                    _handleItem(1, 1)
                    _handleSelectItem(4)
                  }}
                />
                {/* <View style={styles.separator} /> */}
                {/* <StudyLevels
                  imgs={imgs}
                  levelId={3}
                  info="Use this version if testing before July 1st, 2024"
                  fill={30}
                  title="Amateur Extra License"
                  subTitle="(2020 - 2024)"
                  imgIndex={2}
                  onPress={() => {
                    _handleItem(2, 2)
                    _handleSelectItem(2)
                  }}
                /> */}
                <View style={styles.separator} />
                <StudyLevels
                  imgs={imgs}
                  levelId={3}
                  info="Use this version if testing on or after July 1st, 2024"
                  fill={30}
                  title="Amateur Extra License"
                  subTitle="(2024 - 2028)"
                  imgIndex={2}
                  onPress={() => {
                    _handleItem(2, 2)
                    _handleSelectItem(5)
                  }}
                />
                {/* <Text style={{
                  // fontFamily: Units.ftFamily.MontserratB,
                  fontSize: 14,
                  lineHeight: 22,
                  color: "#010A41",
                  textAlign: "center",
                  marginBottom: 20,
                  marginTop: 10
                }}>Additional resources</Text>
                <StudyLevels 
                    imgs={imgs} 
                    levelId={3} 
                    info="Use this pool if taking the exam before July 1st, 2023" 
                    fill={30} 
                    title="Baofeng Basics"
                    subTitle="(2019 - 2023)"
                    imgIndex={3}
                    onPress={()=>handleBaoFeng()}
                    additional
                /> */}
                <View style={{ height: 400 }} />
              </ScrollView>
            </>
          }
        </View>}
        <Image
          source={
            require('../../assets/images/MaskGroup.png')
          }
          style={{
            width: "100%",
            height: width * 0.20,
            // backgroundColor: "#eee",
            position: "absolute",
            top: 0,
            zIndex: -1
          }}
        />
      </View>
    </View>
  )
};

export default Study;
