
import { StyleSheet, Dimensions } from 'react-native';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: EDColors.white,
    },    
    topContainer: {
        width: '100%',
        shadowOffset: { width: 1, height: 2 },
        shadowColor: "black",
        shadowOpacity: .3,
        shadowRadius: 5,
        elevation: 3,
        marginBottom: 20,
        // position: 'fixed',
        // zIndex: 9,
    },
    qNosContainer: {
        flex: 1,
        marginTop: 10,
    },
    qNoTextSucceed: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        color: EDColors.progress[0],
        marginRight: 10,
    },
    qNoTextFailed: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.small,
        color: EDColors.warning,
        marginRight: 10,
    },
    contentContainer: {
        position: 'relative',
    },
    lessonCon:{
        backgroundColor: EDColors.topbar,
    },
    lessonTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.big,
        color: EDColors.white,
        alignSelf:'center',
        paddingBottom: 10,
    },
    body: {
        flex: 1,
        width: '100%',
        justifyContent: "space-between",
        paddingHorizontal: "20%",
    },
    scoreSection: {
        flexDirection: "row",
        paddingVertical: 30,
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 20,
        marginVertical: 20,
        paddingHorizontal: 20,
        shadowOffset: { width: 1, height: 2 },
        shadowColor: "black",
        shadowOpacity: .3,
        shadowRadius: 5,
        elevation: 3,
    },
    passTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: !Units.isTablet? Units.ftSizes.big: Units.ftSizes.extra,
        color: EDColors.passText,
        lineHeight: Units.isTablet? 50: 40,
    },
    failTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: !Units.isTablet? Units.ftSizes.big: Units.ftSizes.extra,
        color: EDColors.warning,
        lineHeight: Units.isTablet? 50: 40,
    },
    scoreTitleTxt: {
        fontFamily: Units.ftFamily.KarlaR,
        fontSize: Units.ftSizes.middefault,
        lineHeight: 22,
        color: EDColors.text,
    },
    cntTxt: {
        fontFamily: Units.ftFamily.KarlaB,
        fontSize: Units.ftSizes.big,
        lineHeight: Units.isTablet? 30: 26,
        color: EDColors.text,
    },
    subpoolCon: {
        backgroundColor: EDColors.poolbk,
        paddingVertical: Units.isTablet? 20: 10,
        paddingHorizontal: Units.isTablet? "15%": 20,
        shadowColor: "black",
        shadowOffset: {width: 1, height: 1},
        shadowOpacity: .3,
        shadowRadius: 1,
        elevation: 3,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    poolTxt: {
        fontFamily: Units.ftFamily.MontserratR,
        fontSize: Units.ftSizes.medium,
        lineHeight: 22,
        color: EDColors.black,
        textAlign: 'center',
    },
    itemCon: {
        paddingHorizontal: Units.isTablet? "15%": 20,
        paddingVertical: Units.isTablet? 20: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    itemCon2: {
        paddingHorizontal: Units.isTablet? "15%": 20,
        paddingVertical: Units.isTablet? 20: 10,
        backgroundColor: EDColors.poolitem,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    nameCon: {
        width: 100,
    },
    progressCon: {
        justifyContent: "center",
    },
    percentCon: {
        width: 60,
        alignItems: "flex-end",
    },
    txt: {
        fontFamily: Units.ftFamily.KarlaR,
        fontSize: Units.ftSizes.middefault,
        lineHeight: 22,
        color: EDColors.text
    },
    txt2: {
        fontFamily: Units.ftFamily.KarlaR,
        fontSize: Units.ftSizes.middefault,
        lineHeight: 22,
        color: EDColors.text
    },
    actionSection: {
        flex: 1,
        justifyContent: "flex-end",
        width:"100%",
        alignItems: "center",
        paddingTop: 10,
        paddingHorizontal: 20,
        marginVertical: 20,
    },
    reviewSection: {
        // borderColor: EDColors.gradient[0],
        // borderWidth: 1,
        borderRadius: 20,
        shadowOffset: { width: 1, height: 2 },
        shadowColor: "black",
        shadowOpacity: .3,
        shadowRadius: 5,
        elevation: 3,
    }
});

export default styles;