import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
    View,
    TouchableOpacity,
    Text,
    Image,
    StyleSheet,
} from 'react-native';
import { EDColors } from '../constants/Colors';
import { Units } from '../constants/Unit';
import { Assets } from '../constants/Images';

const TopBar = ({ title, isMobile, leave, subtitle, history, practice }) => {

    return (
        <View style={[styles.headerCon]}>
            <TouchableOpacity style={styles.leftCon} onPress={() => { practice ? history.go(-2) : history.goBack() }}>
                <Image
                    source={Assets.close}
                    style={{ width: 16, height: 16, marginRight: 5 }}
                />
                {!isMobile && <Text style={styles.backTxt}>{leave ? "Leave Exam" : "Back"}</Text>}
            </TouchableOpacity>
            <View style={isMobile ? { alignItems: "center" } : { alignItems: "center", marginLeft: -80 }}>
                <Text style={[styles.title]}>{title}</Text>
                <Text style={styles.subtitle}>{subtitle}</Text>
            </View>
            <View ></View>
        </View>
    );
}

const styles = StyleSheet.create({
    headerCon: {
        width: '100%',
        backgroundColor: EDColors.topbar,
        paddingHorizontal: Units.isTablet ? 30 : 10,
        paddingTop: Units.isTablet ? Units.statusH + 20 : Units.statusH + 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center",
    },
    leftCon: {
        flexDirection: 'row',
        alignItems: "center",
    },
    backTxt: {
        fontFamily: Units.ftFamily.KarlaR,
        color: EDColors.white,
        fontSize: Units.ftSizes.middefault,
        marginLeft: 5,
    },
    title: {
        fontFamily: Units.ftFamily.MontserratR,
        color: EDColors.white,
        fontSize: Units.ftSizes.medium,
        marginTop: 3,
    },
    subtitle: {
        fontFamily: Units.ftFamily.MontserratB,
        color: EDColors.secondary,
        fontSize: Units.ftSizes.big,
        marginTop: 5,
    },
    logout: {
        paddingHorizontal: 10,
    }
})
export default TopBar;