/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import {
    View,
    StyleSheet,
} from 'react-native';
import 'react-native-gesture-handler';
import { useFonts } from "expo-font";

import { Provider } from 'react-redux';
import configureStore from './src/store';
import MainRoute from './src/Route';
import { EDColors } from './src/constants/Colors';
import { Units } from './src/constants/Unit';
import FullStory from 'react-fullstory';
import { PersistGate } from 'redux-persist/integration/react'
import Toast from 'react-native-toast-message';
const { persistor, store } = configureStore();

const ORG_ID = 'R3QT9';

const App = () => {
    const [loaded] = useFonts(Units.fonts);

    if (!loaded) {
        return null;
    }
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <View style={styles.container}>
                    <FullStory org={ORG_ID} />
                    <MainRoute />
                    <Toast />
                </View>
            </PersistGate>
        </Provider>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: EDColors.topbar,
        backgroundColor: '#fff',
    },
});

export default App;
